const defaultManagerPhone = '+420 730 779 827'

export const defaultManager = {
  name: 'Matěj Bříza',
  phone: defaultManagerPhone,
  phoneHref: `tel:${defaultManagerPhone.replace(/ /g, '')}`,
  phoneWithoutPrefix: defaultManagerPhone.replace('+420 ', ''),
}
const b2bMangerPhone = '+420 778 888 184'
export const B2B_MANAGER = {
  name: 'Ing. Jan Řehounek',
  phone: b2bMangerPhone,
  phoneHref: `tel:${b2bMangerPhone.replace(/ /g, '')}`,
  email: 'emitenti@srovnavacdluhopisu.cz',
  photo: '/img/jan-rehounek.jpg',
}
